import {FaTimes} from"react-icons/fa"
function About() {
    return(
      <div id="about-wrapper" className="font-sans">
      <div id="About" className='w-11/12  md:w-3/5 md:pl-10 m-auto text-left'>
        <div className='Title w-full'>
         <span className='block text-5xl text-LightGray'>About</span>
         <h2 className='text-Blue pl-3'>個別指導塾 シュートについて</h2>
        </div>


      </div>
      <div className='leading-loose text-left w-11/12 md:w-3/5 m-auto md:pl-10' >
          <p>個別指導塾シュートは、</p>
          <p>志望校合格・点数アップに強い塾です。</p>
          <p>点数アップ・志望校合格になぜ強いのか。</p>
          <p>その理由は、</p>
          <p className='importantLine'>「人を育てるプロ」「受験のプロ」「西明石のプロ」</p>
          <p>の3つを兼ね備えていることに、他なりません。</p>
      </div>

      <div className='Pro py-5 md:w-3/5 m-auto'>
      <div className='flex w-11/12 m-auto'>
        <h3 className='w-4/5 text-left py-5 text-xl text-Blue'>1. 人を育てるプロ</h3>
        <div className='h-0.5 w-4/5 bg-Blue my-auto mx-auto'></div>
      </div>

      <div className='w-11/12 m-auto text-left leading-loose py-5'>
        <p>西明石のサッカースクール・プエンテFCを長年、運営する中で培ってきたコーチングメソッドがシュートにはあります。</p>
        <p>プロコーチ・プロメンタルトレーナーが在籍している為、『本物の指導』をお届けできます。勉強はもちろん、社会に出てからも土台になるような『本質的な力』を育成します。</p>
        <p>お子様それぞれの個性を見抜いた上での最適な指導や、やる気を引き出し方に自信があります。</p>
      </div>

      <FaTimes className="text-Gray text-6xl block w-full m-auto" />

      </div>

      <div className='Pro py-5 md:w-3/5 m-auto'>
      <div className='flex w-11/12 m-auto'>
        <h3 className='w-4/5 text-left py-5 text-xl text-Blue'>2. 受験のプロ</h3>
        <div className='h-0.5 w-full bg-Blue my-auto mx-auto'></div>
      </div>

      <div className='w-11/12 m-auto text-left leading-loose py-5'>
        <p>教育歴15年、2000人の生徒を指導してきた中で培った学校情報、受験情報、指導ノウハウ、勉強のテクニックを豊富に兼ね備えてます。</p>
        <p>個別指導塾シュートでは長年の経験から、小手先のテクニック ではなく、「勉強のやり方」「勉強をやるときの考える力の育成」に力を入れます。</p>
        <p>この2点を伸ばすことが、「成績が伸び続けること」に最も繋 がると確信しているからです。</p>
        <p>また、シュートでは全教科の勉強を「お子様が、今日、何を、どのようにすれば良いか」が一目で分かる計画表をお子様ごとに作成します。</p>
        <p>5教科の点数が上がって初めて点数アップだと捉えており、シュートはあくまで全教科の点数アップにこだわります。</p>

        <img src="https://cachitto-page.com/image/keikakuhyo.jpg" alt="計画表" />
      </div>

      <FaTimes className="text-Gray text-6xl block w-full m-auto" />


      </div>

      <div className='Pro my-10 md:w-3/5 m-auto'>
      <div className='flex w-11/12 m-auto'>
        <h3 className='inline w-4/5 text-left py-5 text-xl text-Blue'>3. 西明石のプロ</h3>
        <div className='h-0.5 w-3/5 bg-Blue my-auto mx-auto'></div>
      </div>

      <div className='w-11/12 m-auto text-left leading-loose py-5'>
        <p>西明石で250人の生徒を見てきた、西明石指導のプロです。</p>
        <p>兵庫県立公立高校や、神戸大学、兵庫県立大学、関西学院大学の入試情報はもちろん、周辺の学校情報や野々池中・望海中の定期テストの問題の特徴など、具体的な内容にまで精通してます。</p>
      </div>

      <img className="w-full md:w-1/2 m-auto" src="./assets/delta.jpg" alt="3つのプロ" />






      </div>

      

      <div>
        
      </div>

      



    </div>
    ) 
}

export default About;