import './App.css';
import Top from './Components/Top';
import About from './Components/About';
import Staff from './Components/Staff';
import Point from './Components/Point';
import Enrollment from './Components/Enrollment';
import Stretch from './Components/Stretch';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import { Routes, Route,} from 'react-router-dom';
import Home from "./Pages/Home";
import Policy from "./Pages/Policy";





function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/policy' element={<Policy />}></Route>
      </Routes>
      <Footer/>



    </div>
  

    

  );
}

export default App;
