import { Pagination, Autoplay,Scrollbar} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/bundle';
import { FaBluetooth } from 'react-icons/fa';


function Top() {
    return(
       <main id="Home" className='font-sans'>
    <Swiper
    className='md:hidden m-0 p-0'
    modules={[Pagination, Autoplay, Scrollbar]}
    spaceBetween={50}
    slidesPerView={1}
    pagination={{
      dynamicBullets: true,
      color:"Blue",
    }}
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
    autoplayTimeout={5}
    speed={3000}
    loop={true}
  
    >
      <SwiperSlide className='block p-auto'><img src="./assets/topimage.jpg" alt="" /></SwiperSlide>
    </Swiper>

    <div>
      <img className='pt-16 hidden md:block' src="./assets/topimage_pc.jpg" alt="topimg" />
    </div>
      <div id='logo'>
        <img className='w-1/2 md:w-1/4 m-auto' src="./assets/shootlogo.jpg" alt="" />
      </div>

      <div className='h-15 py-5 bg-Polar flex justify-center font-sans'>
        <div className='w-px h-15 mx-5 bg-Blue -rotate-12'></div>
        <p className='text-2xl text-Blue'>明石市松の内2丁目に<br />新規開講</p>
        <div className='w-px h-15 mx-5 bg-Blue rotate-12'></div>

      </div>

      <div id='access' className='text-center w-2/3 m-auto list-disc py-5 text-Blue'>
        <ul className='font-sans'>
          <li>□JR西明石駅東口より徒歩6分</li>
          <li>□ホテルキャッスルプラザ近く</li>
        </ul>

        <div className='py-5 text-center font-sans container'>
         <p>明石市松の内2丁目8-3</p>
         <p>TEL 078-600-9410</p>

         <button className="bg-Blue block w-full md:w-2/3 m-auto text-White py-2 rounded-3xl my-4"><a href="tel:078-600-9410">お電話のお問い合わせはこちら</a></button>

        </div>

      </div>

      <div id="policy">

        <div className='w-11/12 md:w-2/3 m-auto'>
          
      <h2 className='pt-14 text-left text-2xl  text-Blue'>開塾への想い</h2>
      <h3 className='text-right text-xl py-20 text-Blue'><span className='text-xl pr-5'>個別指導塾シュート代表</span>橋本 英郎</h3>

      <div className='text-left pb-10'>

      <div className='w-1/2 md:w-1/3 relative -top-16 -right-40 '>  
        <img className='w-full rotate-6' src="./assets/hashimoto01.jpg" alt="" />
      </div>

      <div id="text-area" className='w-full pb-10 leading-loose'>
        <p>西明石のサッカースクール・プエンテFCを運営する中で、ずっと感じていたことは「サッカーを通して培った力は、勉強にも社会にも通じる」ということでした。</p>
        <br />
        <p>そんな時、過去にこの西明石で、個別指導塾のスクール長として250人以上のお子様を導いた、受験のプロと出会える機会がありました。</p>
        <br />
        <p>「受験のプロ、西明石のプロの技と、私の人を育てるノウハウで、西明石の子供たちの成長に携わりたい」私の中でもう、止められないほどその想いは強くなりました。</p>
       <br />
        <p>「ただただ真っ直ぐ、お越し頂くお子様に志望合格・点数アップを通して、成長をお届けしたい」個別指導塾シュートは、強い覚悟を持ってお子様に向き合います。</p>
      </div>
      
      </div>



          </div> 
      
      </div>

      <div className='w-2/3 md:w-1/3 m-auto'>
      <div className='flex w-full m-auto'>
        <div className='w-1 h-auto my-1  bg-Blue'></div>
        <h2 className='text-4xl mx-auto text-Blue'>無料体験授業<br />受付中</h2>
        <div className='w-1 h-auto  bg-Blue'></div>
      </div>

      <div className='bg-Gray w-full my-1 text-White text-2xl'>
        <p>小学生~高校生 <br />全教科対象</p>
      </div>



      </div>



      <div id="img-area" className='py-5'>

      <div className='md:w-2/3 m-auto'>
      <div className='w-1/2 block relative left-48'>
        <img src="./assets/sample-image4.jpg" alt="" />
        </div>

        <div className='w-1/2 block float-right pr-12'>
        <img src="./assets/sample-image7.jpg" alt="" />
        </div>


        <div className='w-2/5 block relative left-8  my-12'>
        <img src="./assets/sample-image3.jpg" alt="" />
        </div>

      </div>  




      </div>

       </main>
    ) 
}

export default Top;