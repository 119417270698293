import NaviBar from '../Components/NaviBar';
import Top from "../Components/Top";
import About from "../Components/About";
import Staff from "../Components/Staff";
import Enrollment from "../Components/Enrollment";
import Stretch from "../Components/Stretch";
import Contact from "../Components/Contact";
import FooterActionBar from "../Components/FooterAcitonBar";




function Home () {
    return (
        <div>
            <NaviBar />
            <Top />
            <About />
            <Staff />
            <Stretch />
            <Enrollment />
            <Contact />
            <FooterActionBar />
        </div>
    );
}

export default Home;