
function Stretch() {
    return(
      <div id="Stretch">
       <div className='Title w-11/12 m-auto text-left md:w-3/5'>
         <span className='block  text-5xl text-LightGray'>How to stretch</span>
         <h2 className='text-2xl pl-3 text-Blue'>シュートの伸ばし方</h2>
       </div>

        <img className="w-full md:w-1/3  m-auto" src="./assets/shiboukou.jpg" alt="" />
      </div>
      
    ) 
}

export default Stretch;