
function StaffPanel(props) {
    return (
        <div className="staff my-10 md:w-3/5 m-auto">

         <div className="md:flex">
            <div className="md:w-1/3">
                <img className="h-64 w-auto pr-3" src={'./assets/' + props.img} alt="" />
                <div className="w-full leading-loose ">
                <h4 className="text-xl text-Blue">{props.name}</h4>
                <h4 className="text-xl pb-5 text-Gray">{props.name_english}</h4>
            </div>

            </div>


            <div className="md:w-2/3 my-auto mx-auto">
                  <p className="">{props.profile}</p>
            </div>

         </div>



            <div className="Message my-10 px-2 bg-Polar">
                <h5 className="relative -top-3 left-3">{props.message_from ?? '先生からの'}メッセージ</h5>
                <p className="text-Blue p-2">「{props.message}」</p>
            </div>
        </div>
    )
}

export default StaffPanel;