import AnchorLink from "react-anchor-link-smooth-scroll";

function CircleMenuItem (props) {
    return (
        <li>
            <AnchorLink offset='110' href={props.href}>
                <div className="relative top-7 -rotate-180">{props.name}</div>
            </AnchorLink>
        </li>

    )
}
export default CircleMenuItem;