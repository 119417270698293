import AnchorLink from "react-anchor-link-smooth-scroll";

import { Link } from "react-router-dom";

function NaviLinks (props) {
    return (
        <ul className="md:w-2/3 mx-auto py-5 pl-3 md:flex md:py-0  my-auto md:justify-between leading-loose  ">
        <li className="py-5"> <AnchorLink offset='110' className="" href="#Home">ホーム</AnchorLink ></li>
        <li className="py-5"> <AnchorLink offset='110' className="" href="#About">シュートについて</AnchorLink ></li>
        <li className="py-5"> <AnchorLink offset='110' className="" href="#Staff">スタッフ紹介</AnchorLink ></li>
        <li className="py-5"> <AnchorLink offset='110' className="" href="#Stretch">シュートの伸ばし方</AnchorLink ></li>
        <li className="py-5"> <AnchorLink offset='110' className="" href="#Enrollment">入塾の流れ</AnchorLink ></li>
          
        </ul>
    );
}

export default NaviLinks;