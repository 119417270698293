import CircleMenu from "./CircleMenu";
import Navigation from "./Navigation";


function NaviBar () {
    return (
        <header className="fixed block w-full z-10">
            <Navigation />
            <CircleMenu />
        </header>

    );
}

export default NaviBar;
