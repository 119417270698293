import AnchorLink from "react-anchor-link-smooth-scroll";

function FooterListCol(props) {
    return (
        <li className="py-5">
            <AnchorLink offset='110' className="" href={props.href}>
                {props.name}<br />
                <small>{props.desc}</small>
            </AnchorLink >
        </li>
    )
}

export default FooterListCol;