import NaviLinks from "./NaviLinks";
function Navigation() {
    return (
        
            <nav className="hidden md:flex w-full mx-auto">

                <div id="nav"className="my-auto w-full mx-auto bg-Blue text-White">
                <NaviLinks />
                </div>
            </nav>
       
    );
}

export default Navigation;