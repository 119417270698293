import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
import './index.css';


ReactDOM.render(
  <BrowserRouter>
  <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

