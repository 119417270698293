import FooterListCol from "./Parts/FooterList";

function FooterActionBar() {
    return (
        <div className="bg-Polar text-Blue text-xl leading-tight">
            <ul>
                <FooterListCol name="Home" desc="ホーム" href="#Home" />
                <FooterListCol name="About" desc="シュートについて" href="#About" />
                <FooterListCol name="Staff" desc="スタッフ紹介" href="#Staff" />
                <FooterListCol name="How to Stretch" desc="シュートの伸ばし方" href="#Stretch" />
                <FooterListCol name="Enrollment flow" desc="入塾の流れ" href="#Enrollment" />
            </ul>
        </div>
    ) 
}

export default FooterActionBar;