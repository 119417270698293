import { BsInstagram, BsTwitter,BsFacebook } from "react-icons/bs";


function Footer() {
    return(
      <footer className="bg-Polar text-Blue text-xl leading-tight py-5">

        <button className="bg-Blue block w-72 m-auto my-5 text-White py-1 rounded-3xl"><a href="https://goo.gl/maps/A8v1iAVMHbyx7vXX6" target="_blank">アクセス</a></button>

        <button className="bg-Blue block w-72 m-auto my-5 text-White py-1 rounded-3xl"><a href="https://lin.ee/Zp6Ir3w">お問合せページ</a></button>


        <a className="py-5 block text-Blue" href="/policy" target="_blank">プライバシーポリシー</a>

        <div className="hidden text-4xl text-Blue w-1/2 m-auto flex justify-between py-5">
        <a href="#"> <BsFacebook /></a>
        <a href="#"><BsTwitter /></a>
        <a href="#"> <BsInstagram /></a>
       
        </div>

        <small>Copyright © Shoot All Rights Reserved.</small>

      </footer>
    ) 
}

export default Footer;