
function Contact() {
    return(
      <div className="pt-10">
       <div className='Title w-11/12 m-auto'>
         <span className='block text-5xl text-LightGray'>Contact</span>
         <h2 className='text-2xl pl-3 text-Blue'>お問い合わせ</h2>
       </div>


      <div id="ContactBackgroundImage" className="backgroundImage-contact py-16 md:w-1/3 m-auto text-White bg-Gray ">
         <div>
           <p className="py-5"> ご予約・お問い合わせについては<span className="inline-block">詳細ページをご確認ください。</span></p>

           <button className="bg-Blue w-96 my-12 text-White py-2 rounded-3xl"><a href="https://lin.ee/Zp6Ir3w">LINE公式からのお問い合わせはこちら</a></button>

 
           <button className="bg-Blue w-96 text-White py-2 rounded-3xl"><a href="https://docs.google.com/forms/d/1w3SRFHXrl9o2RRJAcKTpnjpbg7AwSG0Bw6ku2yqZDso/">GoogleFormsからのお問い合わせはこちら</a></button>
           <small className="block my-5">受付時間 月曜~土曜: 14:00~21:00</small>
         </div>
      </div>

      </div>

    ) 
}

export default Contact;