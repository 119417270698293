import { AiOutlineMenu } from "react-icons/ai";
import {useState} from "react";
import CircleMenuItem from "./Parts/MenuItem";

function CircleMenu () {
    const [open,setOpen]= useState (false);
    const closeMobileMenu = () => setOpen(false);

    const menuItem = [
        {href: "#Home", name: "Home"},
        {href: "#About", name: "About"},
        {href: "#Staff", name: "Staff"},
        {href: "#Stretch", name: "How to"},
        {href: "#Enrollment", name: "Flow"}
    ]

    return (
        <nav className="menu md:hidden">
            <div className="relative -top-2 right-3">
                <AiOutlineMenu className="text-4xl text-Gray block" />
            </div>
            <ul>
                {menuItem && menuItem.reverse().map((menu) => (
                    <CircleMenuItem href={menu.href} name={menu.name} />
                ))}
            </ul>
        </nav>
    )
}

export default CircleMenu;