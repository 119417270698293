
function Enrollment() {
    return(
      <div >

        <img className="w-full md:w-1/3 m-auto" id="Enrollment" src="./assets/flow.jpg" alt="入塾の流れ" />
        <img className="w-full md:w-1/3 m-auto" src="./assets/sample-image1.jpg" alt="" />

      </div>
    ) 
}

export default Enrollment;